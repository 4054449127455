/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.cf, header, section, .wrap {
  /* for IE 6/7 */
  /* non-JS fallback */
  zoom: 1;
}

.cf:before, header:before, section:before, .wrap:before,
.cf:after,
header:after,
section:after,
.wrap:after {
  content: "";
  display: table;
}

.cf:after, header:after, section:after, .wrap:after {
  clear: both;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'FKS';
  src: url("../fonts/FKSans-Regular.woff2") format("woff2"), url("../fonts/FKSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FKSExt';
  src: url("../fonts/FKSans-RegularExtended.woff2") format("woff2"), url("../fonts/FKSans-RegularExtended.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

html {
  background-color: white;
}

body, html {
  -webkit-font-smoothing: antialiased;
  font-size: 108%;
  font-family: "FKS", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: #FF3631;
}

em {
  text-decoration: underline;
}

.btn {
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  color: #ffffff;
  font-size: 20px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: solid #ffffff 1px;
  text-decoration: none;
  background: transparent;
  transition: all .3s;
}

.btn:hover {
  transform: scale(0.9);
  cursor: pointer;
}

header {
  background-color: white;
  transition: all 4s;
  height: auto;
  color: #ffffff;
  display: block;
  padding: 0.5rem;
}

header.red {
  background-color: #FF3631;
}

header img.logo {
  float: left;
  width: 40%;
  max-width: 200px;
  margin-bottom: 5rem;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  header img.logo {
    width: 15%;
    margin-bottom: 15rem;
    margin-bottom: 30vh;
  }
}

header h2 {
  clear: both;
  font-family: "FKSExt", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1.8rem;
  letter-spacing: -0.03em;
  padding-bottom: 0.5rem;
  line-height: 0.95;
}

header a.contact {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
}

section {
  padding: 1rem;
  padding-bottom: 8rem;
  background-color: white;
}

section h3 {
  font-family: "FKSExt", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

section p {
  font-size: 1.4rem;
  padding-right: 0.5rem;
  letter-spacing: -0.01em;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  margin-left: 1rem;
}

section ul {
  list-style-type: circle;
  margin: 0;
  padding: 0;
}

section ul li {
  margin-bottom: .5em;
  margin-left: 1em;
}

section#kontakt {
  padding: 0;
}

section#kontakt p {
  line-height: 1.2;
  font-size: 1rem;
  color: #4d4d4d;
}

#map {
  width: 100%;
  min-height: 500px;
  min-height: 50vh;
}

@media (min-width: 992px) {
  #map {
    width: 50%;
    float: left;
  }
}

#sluzby {
  background-color: #ebebeb;
}

@media (min-width: 992px) {
  #sluzby {
    background-color: white;
  }
}

@media (min-width: 992px) {
  .half {
    width: 50%;
    float: left;
  }
}

.info {
  padding: 1rem;
}

.info .btn {
  border-color: #FF3631;
  color: #FF3631;
  display: inline-block;
  margin-top: 3rem;
  padding: 0.5rem 2rem;
}

footer {
  height: 3rem;
  background-color: #ebebeb;
}

footer p {
  padding: 1.15rem 0.5rem;
  float: right;
  font-size: 0.8rem;
  color: #4d4d4d;
}
