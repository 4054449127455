@import '_reset';
@import '_media';


// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;



@font-face {
	font-family: 'FKS';
	src:	url('../fonts/FKSans-Regular.woff2') format('woff2'),
			url('../fonts/FKSans-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'FKSExt';
	src:	url('../fonts/FKSans-RegularExtended.woff2') format('woff2'),
			url('../fonts/FKSans-RegularExtended.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

$sans: 'FKS', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$ext: 'FKSExt', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;




::-webkit-input-placeholder {  }
::-moz-placeholder {  }
:-ms-input-placeholder {  }
:-moz-placeholder {  }

$red: #FF3631;
$white: #ffffff;
$gray: rgb(235, 235, 235);


html {
	background-color: white;
}

body, html {
	-webkit-font-smoothing: antialiased;
	font-size: 108%;
	font-family: $sans;
	color: $red;

}

em {
	text-decoration: underline;
}

.btn {

		-webkit-border-radius: 28;
		-moz-border-radius: 28;
		border-radius: 28px;
		color: #ffffff;
		font-size: 20px;
		padding: 0.5rem 1rem 0.5rem 1rem;
		border: solid #ffffff 1px;
		text-decoration: none;
		background: transparent;
		transition: all .3s;

	  &:hover {
		transform: scale(0.9);
		cursor: pointer;
	}
}


header {
	@extend .cf;
	background-color: white;
	transition: all 4s;
	&.red {
		background-color: $red;
	}

	height:auto;
	color: $white;
	display: block;
	padding: 0.5rem;



	img.logo {
		float: left;
		
		width: 40%;
		max-width: 200px;
		margin-bottom: 5rem;
		top: 0;
		left: 0;
		@include lg {
			width: 15%;
			margin-bottom: 15rem;
			margin-bottom: 30vh;
		}
	}

	h2 {
		clear: both;
		font-family: $ext;
		font-size: 1.8rem;
		letter-spacing: -0.03em;
		padding-bottom: 0.5rem;
		line-height: 0.95;		
		
		// text-align: center;

		// position: absolute;
		// bottom: 10px;
	}

	a.contact {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		font-size: 0.9rem;
		text-transform: uppercase;

	}

}


section {
	@extend .cf;
	padding: 1rem;
	padding-bottom: 8rem;
	background-color: white;

	&.gray {
		// background-color: $gray;
	}
	
	h3 {
		font-family: $ext;
		font-size: 1rem;
		text-transform: uppercase;
		margin-top: 0.5rem;
		margin-bottom: 2rem;
	}
	p {
		font-size: 1.4rem;
		padding-right: 0.5rem;
		letter-spacing: -0.01em;
		margin-bottom:1.5rem;
		line-height: 1.2;
		margin-left: 1rem;
	}

	ul {
		list-style-type: circle;
		margin: 0;
		padding: 0;
		li {
			margin-bottom: .5em;
			margin-left: 1em;
		}
	}

	
}




section#kontakt {
	padding:0;

	p {
		line-height: 1.2;
		font-size: 1rem;
		color: lighten( rgb(0,0,0), 30);
	}
	.info {
		// padding-bottom: 3rem;
	}
}

.wrap {
	@extend .cf;
}

#map {
	width: 100%;
	min-height: 500px;
	min-height: 50vh;
	@include lg {
		width: 50%;
		float: left;

	}
}

#sluzby {
	background-color: $gray;
	@include lg {
		background-color: white;
	}
}

.half {
	@include lg {
		width: 50%;
		float: left;
	}


}

.info {
	padding: 1rem;

	.btn {
		border-color: $red;
		color: $red;
		display: inline-block;
		margin-top: 3rem;
		padding: 0.5rem 2rem;
	}
}


footer {
	height: 3rem; 
	background-color: $gray;
	p {
		padding: 1.15rem 0.5rem;
		float: right;
		font-size: 0.8rem;
		color: lighten( rgb(0,0,0), 30);
	}
}