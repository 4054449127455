@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
 }
 
 // Medium devices
 @mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
 }
 
 // Large devices
 @mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
 }
 
 // Extra large devices
 @mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
 }

// any resolution
 @mixin rwd($screen) {
	@media (min-width: $screen+'px') {
		@content;
	}
 }



 //clearfix
 .cf {
	/* for IE 6/7 */
	/* non-JS fallback */
	zoom: 1;
  }
  
  .cf:before,
  .cf:after {
	content: "";
	display: table;
  }
  
  .cf:after {
	clear: both;
  }
  
//box
* {
	box-sizing: border-box;

}
